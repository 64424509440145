// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Import just the styles you need. Note that some stylesheets are required no matter what.

$primary: #0076bd;
// $secondary: #00e0c1;
$secondary: #3a6f81;
$light: #ecf0f1;

$theme-colors: (
  "psm-l-blue": #34495e,
  "psm-d-blue": #22303f
);


/* $theme-colors: (
  "psm-gray": #9c98ae,
  "psm-l-gray": #f0f3f9,
  "psm-teal": #01e1c2,
  "psm-red": #ed4465,
  "psm-yellow": #f7d439,
  "psm-light-purple": #efecff,
  "psm-light-teal": #ccf9f3,
  "psm-light-red": #fde6eb,
  "psm-light-yellow": #ffffe5
);

$btn-padding-y: 1rem;
$btn-padding-x: 3rem;

$btn-padding-y-sm: .75rem;
$btn-padding-x-sm: 2rem;

$btn-padding-y-lg: 1.25rem;
$btn-padding-x-lg: 3.5rem; */

@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

//
// Override Bootstrap here
//

// Toggle global options
$enable-responsive-font-sizes: true;
$enable-rounded:               false;

// Customize some defaults
// $body-color: $purple;
// $body-bg: #f5f5f5;
// $border-radius: .4rem;

// $font-weight-light: 200;
// $font-weight-normal: 300;
// $font-weight-bold: 600;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group"; // Requires forms
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/print";


//
// HC Off Canvas Import
//

// Core variables
//$hc-offcanvas-nav-transition-duration: .4s !default;
//$hc-offcanvas-nav-transition-timing-function: ease !default;
@import "hc-offcanvas-nav/src/scss/core";
$hc-offcanvas-nav-trigger-color:        $primary;
@import "hc-offcanvas-nav/src/scss/toggle";
// Theme Carbon variables
$hc-offcanvas-nav-background-color:     $primary;
//$hc-offcanvas-nav-text-color:         #fff !default;
//$hc-offcanvas-nav-text-size:          15px !default;
@import "hc-offcanvas-nav/src/scss/theme-carbon";

// HC Off Canvas Custom styles
.hc-offcanvas-nav {
  // font-family: "Rubik", sans-serif;
  .nav-close-button {
    span {
      &::before {
        box-sizing: content-box;
      }
      &::after {
        box-sizing: content-box;
      }
    }
  }
  .nav-item-link {
    color: #fff !important; /* stylelint-disable-line declaration-no-important */
  }
}
.main-nav-toggle {
  position: relative;
  top: auto;
}
.hc-nav-trigger.toggle-open {
  span {
    background: $primary;
    transform: none;
    &::before {
      transform: none;
    }
    &::after {
      transform: none;
    }
  }
  &::before {
    transform: none;
  }
  &::after {
    transform: none;
  }
}
.hc-nav-trigger {
  span {
    height: 2px;
    transition: none;
    transform: none;
    transform-origin: 0;
    &::before {
      height: 2px;
      transition: none;
    }
    &::after {
      height: 2px;
      transition: none;
    }
  }
}


//
// Custom styles
//

